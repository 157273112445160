import { getDateTimeItems, PrecisionValues, ReturnIntervalValues, ReturnNumberValues } from '@/store/kyc/helpers';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import { attributeFilterSchemaModule } from '@/components/common/filter/filters/AttributeFilterSchemaModule';
import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';
import { SimpleFilterOptions } from '@/components/common/filter/filters/types';

export function getKycFilters(options: SimpleFilterOptions) {
  const items = [
    commonFilterSchemaModule.getDateTimeRange(options),
    commonFilterSchemaModule.getPrecision({ ...options, items: getDateTimeItems(PrecisionValues) }),
    commonFilterSchemaModule.getReturnInterval({ ...options, items: getDateTimeItems(ReturnIntervalValues) }),
    commonFilterSchemaModule.getReturnsNumber({ ...options, items: ReturnNumberValues.map((value) => ({ value, label: value.toString() })) }),
    commonFilterSchemaModule.getCamerasDependedOnCameraGroups(options),
    commonFilterSchemaModule.getWatchLists(options),
    attributeFilterSchemaModule.getFaceAge(options),
    attributeFilterSchemaModule.getFaceGender(options)
  ];

  return items.filter((v) => v !== undefined) as IFormLayoutItem[];
}
