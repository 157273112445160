
import { graphic } from 'echarts';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
  AxisPointerOpacity,
  getThemeTokenValue,
  GradientColors,
  LineColorToken,
  TextColorToken,
  TooltipBackgroundColorToken,
  TooltipColorToken
} from '@/pages/kyc/components/chart-helpers';
import { KycFilter } from '@/api/models/KycFilter';
import { KycItem } from '@/store/kyc/types';
import { RouterModule } from '@/store/router';
import NButton from '@/uikit/buttons/NButton.vue';
import Chart from '@/pages/kyc/components/Chart.vue';
import ChartGraph from '@/pages/kyc/components/ChartGraph.vue';
import ChartPager from '@/pages/kyc/components/ChartPager.vue';
import ChartStatistic from '@/pages/kyc/components/ChartStatistic.vue';

function getBarColor(type: keyof typeof GradientColors) {
  return new graphic.LinearGradient(1, 0, 0, 0, GradientColors[type]);
}

function getPageItems(items: any[], indices: any[]) {
  return (items && items.slice(...indices)) || [];
}

const MaxCamerasOnGraph = 6;

@Options({
  name: 'BarChart',
  components: { Chart, ChartGraph, ChartPager, NButton },
  emits: ['activate', 'show-fullscreen', 'download']
})
export default class BarChart extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: KycItem;

  @Prop({ type: Object, required: true })
  readonly filter!: KycFilter;

  @Prop({ type: Boolean, default: false })
  readonly full!: boolean;

  pageSize = MaxCamerasOnGraph;
  indices = [0, 0];

  get options() {
    const titleText = this.$t(this.item.type, 'f');
    const barColor1 = getBarColor('unique');
    const barColor2 = getBarColor('all');

    return {
      title: {
        show: false,
        text: titleText,
        textStyle: {
          color: getThemeTokenValue(TextColorToken)
        }
      },
      tooltip: {
        show: this.full,
        axisPointer: {
          type: 'cross',
          lineStyle: {
            opacity: AxisPointerOpacity
          },
          label: {
            backgroundColor: getThemeTokenValue(TooltipBackgroundColorToken),
            shadowBlur: 0,
            fontSize: 10,
            formatter: ({ value }: any) => (isNaN(value) ? value : Math.round(value))
          },
          padding: 10,
          textStyle: {
            color: getThemeTokenValue(TooltipColorToken)
          }
        },
        trigger: 'axis'
      },
      xAxis: {
        splitLine: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: getThemeTokenValue(LineColorToken)
          }
        }
      },
      yAxis: {
        splitLine: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: getThemeTokenValue(LineColorToken)
          }
        },
        data: this.cameras
      },
      series: [
        {
          name: this.$t('kyc.unique', 'f'),
          type: 'bar',
          itemStyle: {
            color: barColor1
          },
          label: {
            show: this.full,
            color: getThemeTokenValue(TextColorToken),
            position: 'right'
          },
          barMaxWidth: '24px',
          data: this.uniqueVisitors
        },
        {
          name: this.$t('kyc.total', 'f'),
          type: 'bar',
          itemStyle: {
            color: barColor2
          },
          label: {
            show: this.full,
            color: getThemeTokenValue(TextColorToken),
            position: 'right'
          },
          barMaxWidth: '24px',
          data: this.totalVisitors
        }
      ],
      grid: {
        show: this.full,
        top: '48px',
        left: '32px',
        right: '32px',
        bottom: '8px',
        containLabel: true
      }
    };
  }

  get xAxisData() {
    return this.item.data.xAxisData || [];
  }

  get yAxisData() {
    return this.item.data.yAxisData || [];
  }

  get uniqueVisitors() {
    return getPageItems(this.xAxisData[0], this.indices);
  }

  get totalVisitors() {
    return getPageItems(this.xAxisData[1], this.indices);
  }

  get cameras() {
    return getPageItems(this.yAxisData, this.indices);
  }

  handlePageChangeEvent(indices: any[]) {
    this.indices = indices;
  }
}
